<template>
    <card-table-search title="Liste de vos réservations du local" :items="reservations" :fields="fields"
                       :actions="actions" :custom-rendered="['valide']">
        <template #title>
            Liste de vos réservations du local
            <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Nouvelle demande'"
                      @click="$router.push({name: 'creation_reservation'})">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>
        <template #valide="slotProps">
            <b-badge :variant="getBadgeColorStatut(slotProps.row.item)">
                {{ getTextStatut(slotProps.row.item) }}
            </b-badge>
        </template>
    </card-table-search>
</template>

<script>
    const CardTableSearch = () => import('@/components/CardTableSearch');

    import {apiPath}         from '@/util/http';
    import demande           from '@/util/demande';
    import alert             from '@/util/alert';
    import reservationsMixin from '@/mixin/reservationsMixin';

    export default {
        name: "ReservationsDashboard",
        components: {CardTableSearch},
        mixins: [reservationsMixin],
        data() {
            return {
                reservations: [],
                fields: [
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => demande.formatDates(item)
                    },
                    {
                        key: 'valide',
                        label: 'Statut',
                        sortable: true,
                        formatter: (value, key, item) => demande.getTextStatut(item)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Détail',
                        handler: ({item}) => this.displayReservationModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editReservationModal(item),
                        display: ({item}) => item.valide === null
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.deleteReservation(item),
                        display: ({item}) => item.valide === null
                    }
                ]
            };
        },
        methods: {
            ...demande,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_user_reservations'))
                    .then(response => this.reservations = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste de vos réservations'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
